// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/qrcode_scan.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./scan_qr_code.scss"></require>
  <section id="scan_qr_code" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="unsorted.scan_qr_code"></h1>
    </div>
    <div class="flex-grow"></div>
    <div class="video">
      <img src="${___HTML_LOADER_IMPORT_1___}" class="scanner">
      <video element.ref="camera">
      </video>
    </div>
    <h3 t="unsorted.scan_here"></h3>
    <div class="flex-grow"></div>
    <button id="scan_qr_code.back" class="btn btn-primary" click.delegate="goBack()" t="home.back"></button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;